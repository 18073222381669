.video-item {
    display: flex !important;
    align-items: center !important;
    cursor: pointer;

    &.item {
        img {
            max-width: 180px;
        }
    }
}
